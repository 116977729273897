<script>
    import { onMount, onDestroy } from 'svelte';

    export let data = {}

    let contentElement
    let originalParent

    onMount(() => {
        originalParent = data.content.parentNode
        contentElement.appendChild(data.content)
    })

    onDestroy(() => {
        if (originalParent) {
            originalParent.appendChild(data.content)
        }
    })
</script>

<div bind:this={contentElement} class="modal-content">
</div>
