export function extend(obj, src) {
    for (var key in src) {
        if (src.hasOwnProperty(key))
            obj[key] = src[key];
    }
    return obj;
}
export default class BaseButton {
    constructor(elem, name, options) {
        this.name = name;
        this.el = elem;
        this.cookieExpiresInDays = 7;
        let defaults = {
            classPressed: 'active',
            defaultStatus: 'released',
            title: 'Set ' + this.name + ' active/inactive',
            textPressed: null,
            textReleased: null,
            actionPressed: null,
            actionReleased: null
        };
        options = extend(defaults, options);
        extend(this, options);
        // events
        this.el.addEventListener('click', e => {
            e.preventDefault();
            this.toggleStatus();
        });
        this.el.title = this.title;
        // init status depending on cookie
        if (this.getCookieVal() === 'active') {
            this.setStatusPressed();
        }
        else {
            this.setStatusReleased();
        }
    }
    getCookieVal() {
        const cookie = readCookie(this.name);
        return (cookie === "") ? false : cookie;
    }
    setCookieVal(v) {
        createCookie(this.name, v, this.cookieExpiresInDays);
    }
    eraseCookie() {
        eraseCookie(this.name);
    }
    setStatusPressed() {
        this.status = 'active';
        if (!this.el.classList.contains(this.classPressed)) {
            this.el.classList.add(this.classPressed);
        }
        if (this.textPressed) {
            this.el.textContent = this.textPressed;
        }
        this.setCookieVal(this.status);
        if (this.actionPressed) {
            this.actionPressed();
        }
    }
    setStatusReleased() {
        this.status = 'released';
        if (this.el.classList.contains(this.classPressed)) {
            this.el.classList.remove(this.classPressed);
        }
        if (this.textPressed) {
            this.el.textContent = this.textReleased;
        }
        this.eraseCookie();
        if (this.actionReleased) {
            this.actionReleased();
        }
    }
    toggleStatus() {
        if (this.status === 'active') {
            this.setStatusReleased();
        }
        else if (this.status === 'released') {
            this.setStatusPressed();
        }
    }
}
function createCookie(name, value, days) {
    let date, expires;
    if (days) {
        date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}
function readCookie(name) {
    var i, c, nameEQ = name + "=", ca = document.cookie.split(';');
    for (i = 0; i < ca.length; i++) {
        c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}
function eraseCookie(name) {
    createCookie(name, "", -1);
}
