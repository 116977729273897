<script>
    import { v4 as uuid_v4 } from 'uuid';
    import { onMount, onDestroy } from 'svelte'
    import Modal from './Modal.svelte'

    let modals = []
    let modalRefs = {}
    let scrollbarIsSet = false

    function updateModalRef(id, bindings) {
        modalRefs[id] = bindings
    }

    function closeModal(id) {
        modals = modals.filter(m => m.id !== id)

        if (!modals || !modals.length) {
            scrollbarUnset()
        }
    }

    function handleKeyPress(e) {
        // ESC
        if (e.keyCode === 27) {
            closeTopModal()
        }
    }

    function closeTopModal() {
        if (!modals || !modals.length) {
            return
        }

        const id = modals[modals.length-1].id
        if (modalRefs[id]) {
            modalRefs[id].close()
        }
    }

    function scrollbarSet() {
        scrollbarIsSet = true

        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

        document.body.classList.add('modal-open')
        document.body.style.paddingRight = scrollbarWidth + 'px'
    }

    function scrollbarUnset() {
        scrollbarIsSet = false

        document.body.classList.remove('modal-open')
        document.body.style.paddingRight = '0'
    }

    function addModal(content, options = {}) {
        scrollbarSet()

        modals = [...modals, {
            id: uuid_v4(),
            type: 'html',
            props: {},
            content, ...options
        }]
    }

    onMount(() => {
        document.addEventListener('keydown', handleKeyPress)
        window.ModalManager = {
            showModal: addModal
        }
    })

    onDestroy(() => {
        document.removeEventListener('keydown', handleKeyPress)
    })
</script>

<div id="modal-list">
    {#each modals as modal (modal.id)}
        {#if modal.type === 'component'}
            <Modal data={modal} closeModal={closeModal} updateRef={updateModalRef}>
                <svelte:component this={modal.content} {...modal.props} />
            </Modal>
        {:else}
            <Modal data={modal} closeModal={closeModal} updateRef={updateModalRef} />
        {/if}
    {/each}
</div>
